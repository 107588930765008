import React from "react";
import styled from "styled-components";

const DotElement = styled.span`
  display: inline-block;
  width: 1.25vw;
  height: 1.25vw;
  min-width: 12px;
  min-height: 12px;
  align-self: start;
  border-radius: 100%;
  background: #ff194b;
`;

const Dot = () => {
  return <DotElement />;
};

export default Dot;
