import React from "react";
import "./ArtistPage.styles.scss";
import BlockContent from "@sanity/block-content-to-react";
import sanityClient from "../client.js";
import { animated, useSpring } from "react-spring";

import { Link } from "react-router-dom";

// import {BiX as CloseIcon} from 'react-icons/bi'
// import {IoCloseOutline as CloseIcon} from 'react-icons/io5'

import Dot from "../components/dot/dot.component";
import DotOutline from "../components/dot/dot-outline.component";

// import loader

const ArtistPage = ({ content, ticketUrl, handleCloseArtist }) => {
  const artistPageSpring = useSpring({
    transform: content ? "translatex(0%)" : "translatex(100%)",
    opacity: content ? 1 : 0,
    from: { transform: "translatex(100%)", opacity: 0 },
    config: { tension: 250 },
  });

  const serializers = {
    list: (props) => <div className="list">{props.children}</div>,
    listItem: (props) => (
      <div className="list-item-wrapper">
        <DotOutline />
        <div className="list-item">{props.children}</div>
      </div>
    ),
    marks: {
      indent: (props) => (
        <span className="paragraph-indent">{props.children}</span>
      ),
      dot: (props) => (
        <span className="dot-title-wrapper">
          <Dot />
          <span>{props.children}</span>
        </span>
      ),
    },
  };

  // const [artistContent, setArtistContent] = useState(null)
  // let { slug } = useParams();

  // useEffect(() =>{
  //     if (!slug) return;
  //     let fetchContentQuery = `*[slug.current == '${slug}']{_id}[0]`;
  //     sanityClient
  //     .fetch(fetchContentQuery)
  //     .then(data => {setArtistContent(data)})
  //     .catch(console.error)
  // },[slug]);

  // useEffect(() =>{
  //     console.log(artistContent);
  // },[artistContent])

  const returnDay = (time) => {
    let dayNumber = new Date(time).getDate().toString();

    switch (dayNumber) {
      case "23":
        return "Onsdag 23. april";
        break;
      case "24":
        return "Torsdag 24. april";
        break;
      case "25":
        return "Fredag 25. april";
        break;
      case "26":
        return "Lørdag 26. april";
        break;
      case "27":
        return "Søndag 27. april";
        break;
      case "28":
        return "Mandag 28. mai";
        break;
      default:
        return "";
    }
  };

  if (!content) return <></>;
  return (
    <animated.div style={artistPageSpring} className="artist-page-wrapper">
      <Link
        to={`/`}
        className="close"
        onClick={() => handleCloseArtist()}
      ></Link>
      <div className="artist-page-content">
        <div className="artist-page-image">
          <img src={content.imageUrl}></img>
        </div>
        <div>
          <div className="artist-page-meta-section">
            <Dot />

            {content.title}
          </div>

          {!content.timeTBA ? (
            <div className="artist-page-meta-section">
              {returnDay(content.time)},{" "}
              {new Date(content.time).toLocaleTimeString("en-GB", {
                timeStyle: "short",
              })}
            </div>
          ) : (
            ""
          )}

          {content.place.length > 1 ? (
            <div className="artist-page-meta-section">{content.place}</div>
          ) : (
            ""
          )}

          {content.price.length > 1 ? (
            <div className="artist-page-meta-section">{content.price}</div>
          ) : (
            ""
          )}
        </div>
        <a
          className="artist-page-ticket-button"
          href={content.artistTicketUrl ? content.artistTicketUrl : ticketUrl}
        >
          Kjøp billett
        </a>
        <div className="artist-page-text">
          <BlockContent
            blocks={content.text}
            serializers={serializers}
            projectId={sanityClient.clientConfig.projectId}
            dataset={sanityClient.clientConfig.dataset}
          />
        </div>
      </div>
    </animated.div>
  );
};

export default ArtistPage;
