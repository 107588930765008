import React, { useEffect, useState, useRef } from "react";
import sanityClient from "../client.js";
import BlockContent from "@sanity/block-content-to-react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";

import { TfiClose as CloseIcon } from "react-icons/tfi";

import { useLocation, Link } from "react-router-dom";

import "./frontpage.styles.scss";

import ArtistPage from "./ArtistPage.page";

import Dot from "../components/dot/dot.component";
import DotOutline from "../components/dot/dot-outline.component";
import Billettknapp from "../components/billettknapp/billettknapp.component";
import Accordion from "../components/accordion/accordion.component";

const SoddVideo = styled.video`
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -webkit-appearance: none;
  mix-blend-mode: multiply;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const SoddVideoNarrow = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -webkit-appearance: none;
  mix-blend-mode: multiply;
`;

const Frontpage = () => {
  const [daySelected, setDaySelected] = useState(0);

  const [contentVisible, toggleContentVisible] = useState(false);
  const [titleVisible, toggleTitleVisible] = useState(false);

  const [pageContent, setPageContent] = useState(null);

  const [displayArtist, setDisplayArtist] = useState(null);
  const [showTekstBoks, setShowTekstboks] = useState(true);

  const [titleHeight, setTitleHeight] = useState(0);

  const location = useLocation();

  const contentRef = useRef();

  const isNarrow = useMediaQuery({ query: "(max-width: 1100px)" });

  useEffect(() => {
    let fetchContentQuery = `{'artists': *[_type == 'artist23']{_id,title, slug, time, timeTBA, place, price, artistTicketUrl, text, 'imageUrl': image.asset->url} | order(time asc),'about': *[_id == 'about23']{text, aboutReady}[0],'info': *[_id == 'info23']{text, infoReady}[0],'SO': *[_id == 'SOpage']{title,text}[0],'festivalArtist': *[_id == 'festivalArtist23']{text, festivalArtistReady}[0],'contact': *[_id == 'contact']{textCol1, textCol2, contactReady}[0],'partners':*[_id=='partners']{partnersReady,mainPartnerList[]{'url':asset->url},partnerList[]{'url':asset->url, caption}}[0],'control': *[_id == 'control']{ticketUrl,artistsReady, programmeReady}[0],'midlertidig': *[_id == 'midlertidig']{text, showMidlertidig}[0]}`;
    sanityClient
      .fetch(fetchContentQuery)
      .then((data) => {
        setPageContent(data);
      })
      .catch(console.error);

    if (!isNarrow) {
      setTimeout(() => {
        toggleTitleVisible(true);
      }, 1000);
      setTimeout(() => {
        toggleContentVisible(true);
      }, 3500);
    } else {
      // console.log('no toggle');
      toggleTitleVisible(true);
      toggleContentVisible(true);
    }
  }, []);

  useEffect(() => {
    const cleanLocation = location.pathname.replace("/", "");
    if (!cleanLocation || !pageContent) return;
    else {
      const currentArtist = pageContent.artists.filter((artist) => {
        return artist.slug.current == cleanLocation;
      });
      setDisplayArtist(currentArtist);
    }
  }, [pageContent]);

  const serializers = {
    list: (props) => <div className="list">{props.children}</div>,
    listItem: (props) => (
      <div className="list-item-wrapper">
        <DotOutline />
        <div className="list-item">{props.children}</div>
      </div>
    ),
    marks: {
      indent: (props) => (
        <span className="paragraph-indent">{props.children}</span>
      ),
      dot: (props) => (
        <span className="dot-title-wrapper">
          <Dot />
          <span>{props.children}</span>
        </span>
      ),
      em: (props) => <span>«{props.children}»</span>,
      link: ({ mark, children }) => {
        const { href } = mark;
        if (href.includes("http://") || href.includes("https://")) {
          return (
            <a href={href} target="_blank" rel="noopener">
              {children}
            </a>
          );
        } else {
          return (
            <a href={`http://${href}`} target="_blank" rel="noopener">
              {children}
            </a>
          );
        }
      },
    },
  };

  useEffect(() => {
    if (!contentRef.current) {
      return;
    } else if (isNarrow) {
      setTitleHeight(90);
    } else if (contentRef.current.clientHeight) {
      const newTitleHeight = Math.round(
        window.innerHeight -
          contentRef.current.clientHeight -
          window.innerWidth * 0.03
      );
      // console.log(newTitleHeight);
      setTitleHeight(newTitleHeight);
    }
  });

  useEffect(() => {
    const cleanLocation = location.pathname.replace("/", "");
    if (cleanLocation && pageContent) {
      // Set displayArtist
      const currentArtist = pageContent.artists.filter((artist) => {
        return artist.slug.current == cleanLocation;
      });
      setDisplayArtist(currentArtist);
    }
  }, [location]);

  const handleCloseArtist = () => {
    //reset location
    setDisplayArtist(null);
  };

  const doesDayHaveArtists = (artists, timeSpan) => {
    // console.log(timeSpan);
    // console.log(artists);

    let minDate;
    let maxDate;

    if (timeSpan == "23") {
      minDate = new Date("2025-04-23").toISOString();
      maxDate = new Date("2025-04-24").toISOString();
    } else if (timeSpan == "24") {
      minDate = new Date("2025-04-24").toISOString();
      maxDate = new Date("2025-04-25").toISOString();
    } else if (timeSpan == "25") {
      minDate = new Date("2025-04-25").toISOString();
      maxDate = new Date("2025-04-26").toISOString();
    } else if (timeSpan == "26") {
      minDate = new Date("2025-04-26").toISOString();
      maxDate = new Date("2025-04-27").toISOString();
    } else if (timeSpan == "27") {
      minDate = new Date("2025-04-27").toISOString();
      maxDate = new Date("2025-04-28").toISOString();
    }

    const filtered = artists.filter((artist) => {
      // console.log(artist.time, minDate, maxDate);
      // console.log(artist.time > minDate && artist.time < maxDate);
      return artist.time > minDate && artist.time < maxDate;
    });

    // console.log(timeSpan, filtered)

    if (filtered.length == 0) {
      return false;
    } else {
      return true;
    }
  };

  const Tekstboks = ({ content }) => {
    // console.log(content)

    const serializers = {
      marks: {
        opp: (props) => <span className="opp">{props.children}</span>,
        ned: (props) => <span className="ned">{props.children}</span>,
      },
    };

    return (
      <div className="tekstboks">
        <div
          className="tekstboks-close"
          onClick={() => setShowTekstboks(false)}
        >
          <CloseIcon />
        </div>

        <div className="tekstboks-tekst">
          <BlockContent
            blocks={content}
            serializers={serializers}
            projectId={sanityClient.clientConfig.projectId}
            dataset={sanityClient.clientConfig.dataset}
          />
        </div>
      </div>
    );
  };

  if (!pageContent) return <></>;
  return (
    <div>
      {/* {showTekstBoks ? 
            <Tekstboks content={pageContent.midlertidig.text}/>
            :''} */}
      {isNarrow ? (
        <>
          {displayArtist ? (
            <ArtistPage
              ticketUrl={pageContent.control.ticketUrl}
              content={displayArtist[0]}
              handleCloseArtist={handleCloseArtist}
            />
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
      <div className="half padding">
        <div
          className={`main-title-wrapper ${
            titleVisible ? "title-visible" : ""
          }`}
          style={{ height: titleHeight }}
        >
          <div className="title">
            <span className="title-section">
              S<span className="title-letter t1">O</span>DDJA
              <span className="title-letter t2">Z</span>Z
            </span>{" "}
            <span className="title-section">
              2<span className="title-letter t3">3</span>.&ndash;27.4.2
              <span className="title-letter t4">0</span>25
            </span>
          </div>
          <Billettknapp
            url={pageContent.control.ticketUrl}
            visible={contentVisible}
          />
        </div>
        {isNarrow ? (
          <div
            className="narrow-video-wrapper"
            style={{ display: `${contentVisible ? "block" : "none"}` }}
          >
            <SoddVideoNarrow
              autoPlay
              playsInline
              muted
              loop
              src={
                process.env.PUBLIC_URL +
                "/assets/Soddjazz2025-webside_2_s_01.mp4"
              }
              type="video/mp4"
            />
          </div>
        ) : (
          ""
        )}
        <div
          className="main-content-wrapper"
          ref={contentRef}
          style={{ opacity: `${contentVisible ? "1" : "0"}` }}
        >
          {pageContent.control.artistsReady ? (
            <Accordion
              title={
                <div className="menu-title">
                  AR
                  <span className="b1">T</span>
                  IST
                  <span className="b2">E</span>R
                </div>
              }
            >
              <div className="accordion-section artist-section">
                {pageContent.artists.map((artist) => {
                  return (
                    <Link key={artist._id} to={`/${artist.slug.current}`}>
                      <div className="artist-preview-wrapper">
                        <div className="image-wrapper">
                          <img src={artist.imageUrl} />
                        </div>
                        <div className="title-wrapper">
                          <div>
                            <Dot />
                          </div>
                          <div>{artist.title}</div>
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </Accordion>
          ) : (
            ""
          )}
          {pageContent.control.programmeReady ? (
            <Accordion
              title={
                <div className="menu-title">
                  PR
                  <span className="b2">O</span>
                  GRA
                  <span className="b1">M</span>
                </div>
              }
            >
              <div className="accordion-section programme-section">
                <div className="artist-programme-wrapper">
                  {doesDayHaveArtists(pageContent.artists, 23) ? (
                    <div className="artist-programme-day">
                      <div className="artist-programme-day-title">
                        <div className="dot-wrapper">
                          <Dot />
                        </div>
                        ONSDAG 23. APRIL - FESTIVALVORSPIEL
                      </div>

                      {!isNarrow ? (
                        <>
                          {pageContent.artists
                            .filter((artist) => {
                              let minDate = new Date(
                                "2025-04-23"
                              ).toISOString();
                              let maxDate = new Date(
                                "2025-04-24"
                              ).toISOString();
                              return (
                                artist.time > minDate && artist.time < maxDate
                              );
                            })
                            .map((artist) => {
                              return (
                                <Link
                                  key={artist.day + artist._id}
                                  to={`/${artist.slug.current}`}
                                >
                                  <div
                                    key={artist._id}
                                    className="artist-programme-entry"
                                  >
                                    <div className="programme-time">
                                      {new Date(artist.time).toLocaleTimeString(
                                        "en-GB",
                                        { timeStyle: "short" }
                                      )}
                                    </div>
                                    <div className="programme-title">
                                      {artist.title}
                                    </div>
                                    <div className="programme-price">
                                      {artist.price.length > 1 && artist.price}
                                    </div>
                                    <div className="programme-place">
                                      {artist.place.length > 1 && artist.place}
                                    </div>
                                  </div>
                                </Link>
                              );
                            })}
                        </>
                      ) : (
                        <>
                          {pageContent.artists
                            .filter((artist) => {
                              let minDate = new Date(
                                "2025-04-23"
                              ).toISOString();
                              let maxDate = new Date(
                                "2025-04-24"
                              ).toISOString();
                              return (
                                artist.time > minDate && artist.time < maxDate
                              );
                            })
                            .map((artist) => {
                              return (
                                <Link
                                  key={artist.day + artist._id}
                                  to={`/${artist.slug.current}`}
                                >
                                  <div
                                    key={artist._id}
                                    className="artist-programme-entry"
                                  >
                                    <div className="mobile-artist-entry">
                                      <div className="programme-time">
                                        {new Date(
                                          artist.time
                                        ).toLocaleTimeString("en-GB", {
                                          timeStyle: "short",
                                        })}
                                      </div>
                                      <div className="programme-title">
                                        {artist.title}
                                      </div>
                                    </div>
                                    <div className="mobile-artist-entry">
                                      <div className="programme-price">
                                        {artist.price.length > 1 &&
                                          artist.price}
                                      </div>
                                      <div className="programme-place">
                                        {artist.place.length > 1 &&
                                          artist.place}
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              );
                            })}
                        </>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {doesDayHaveArtists(pageContent.artists, 24) ? (
                    <div className="artist-programme-day">
                      <div className="artist-programme-day-title">
                        <div className="dot-wrapper">
                          <Dot />
                        </div>
                        TORSDAG 24. APRIL
                      </div>

                      {!isNarrow ? (
                        <>
                          {pageContent.artists
                            .filter((artist) => {
                              let minDate = new Date(
                                "2025-04-24"
                              ).toISOString();
                              let maxDate = new Date(
                                "2025-04-25"
                              ).toISOString();
                              return (
                                artist.time > minDate && artist.time < maxDate
                              );
                            })
                            .map((artist) => {
                              return (
                                <Link
                                  key={artist.day + artist._id}
                                  to={`/${artist.slug.current}`}
                                >
                                  <div
                                    key={artist._id}
                                    className="artist-programme-entry"
                                  >
                                    <div className="programme-time">
                                      {new Date(artist.time).toLocaleTimeString(
                                        "en-GB",
                                        { timeStyle: "short" }
                                      )}
                                    </div>
                                    <div className="programme-title">
                                      {artist.title}
                                    </div>
                                    <div className="programme-price">
                                      {artist.price.length > 1 && artist.price}
                                    </div>
                                    <div className="programme-place">
                                      {artist.place.length > 1 && artist.place}
                                    </div>
                                  </div>
                                </Link>
                              );
                            })}
                        </>
                      ) : (
                        <>
                          {pageContent.artists
                            .filter((artist) => {
                              let minDate = new Date(
                                "2025-04-24"
                              ).toISOString();
                              let maxDate = new Date(
                                "2025-04-25"
                              ).toISOString();
                              return (
                                artist.time > minDate && artist.time < maxDate
                              );
                            })
                            .map((artist) => {
                              return (
                                <Link
                                  key={artist.day + artist._id}
                                  to={`/${artist.slug.current}`}
                                >
                                  <div
                                    key={artist._id}
                                    className="artist-programme-entry"
                                  >
                                    <div className="mobile-artist-entry">
                                      <div className="programme-time">
                                        {new Date(
                                          artist.time
                                        ).toLocaleTimeString("en-GB", {
                                          timeStyle: "short",
                                        })}
                                      </div>
                                      <div className="programme-title">
                                        {artist.title}
                                      </div>
                                    </div>
                                    <div className="mobile-artist-entry">
                                      <div className="programme-price">
                                        {artist.price.length > 1 &&
                                          artist.price}
                                      </div>
                                      <div className="programme-place">
                                        {artist.place.length > 1 &&
                                          artist.place}
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              );
                            })}
                        </>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {/* FREDAG */}
                  {doesDayHaveArtists(pageContent.artists, 25) ? (
                    <div className="artist-programme-day">
                      <div className="artist-programme-day-title">
                        <div className="dot-wrapper">
                          <Dot />
                        </div>
                        FREDAG 25. APRIL
                      </div>

                      {!isNarrow ? (
                        <>
                          {pageContent.artists
                            .filter((artist) => {
                              let minDate = new Date(
                                "2025-04-25"
                              ).toISOString();
                              let maxDate = new Date(
                                "2025-04-26"
                              ).toISOString();
                              return (
                                artist.time > minDate && artist.time < maxDate
                              );
                            })
                            .map((artist) => {
                              return (
                                <Link
                                  key={artist.day + artist._id}
                                  to={`/${artist.slug.current}`}
                                >
                                  <div
                                    key={artist._id}
                                    className="artist-programme-entry"
                                  >
                                    <div className="programme-time">
                                      {new Date(artist.time).toLocaleTimeString(
                                        "en-GB",
                                        { timeStyle: "short" }
                                      )}
                                    </div>
                                    <div className="programme-title">
                                      {artist.title}
                                    </div>
                                    <div className="programme-price">
                                      {artist.price}
                                    </div>
                                    <div className="programme-place">
                                      {artist.place}
                                    </div>
                                  </div>
                                </Link>
                              );
                            })}
                        </>
                      ) : (
                        <>
                          {pageContent.artists
                            .filter((artist) => {
                              let minDate = new Date(
                                "2025-04-25"
                              ).toISOString();
                              let maxDate = new Date(
                                "2025-04-26"
                              ).toISOString();
                              return (
                                artist.time > minDate && artist.time < maxDate
                              );
                            })
                            .map((artist) => {
                              return (
                                <Link
                                  key={artist.day + artist._id}
                                  to={`/${artist.slug.current}`}
                                >
                                  <div
                                    key={artist._id}
                                    className="artist-programme-entry"
                                  >
                                    <div className="mobile-artist-entry">
                                      <div className="programme-time">
                                        {new Date(
                                          artist.time
                                        ).toLocaleTimeString("en-GB", {
                                          timeStyle: "short",
                                        })}
                                      </div>
                                      <div className="programme-title">
                                        {artist.title}
                                      </div>
                                    </div>
                                    <div className="mobile-artist-entry">
                                      <div className="programme-price">
                                        {artist.price}
                                      </div>
                                      <div className="programme-place">
                                        {artist.place}
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              );
                            })}
                        </>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {/* LØRDAG */}
                  {doesDayHaveArtists(pageContent.artists, 26) ? (
                    <div className="artist-programme-day">
                      <div className="artist-programme-day-title">
                        <div className="dot-wrapper">
                          <Dot />
                        </div>
                        LØRDAG 26. APRIL
                      </div>

                      {!isNarrow ? (
                        <>
                          {pageContent.artists
                            .filter((artist) => {
                              let minDate = new Date(
                                "2025-04-26"
                              ).toISOString();
                              let maxDate = new Date(
                                "2025-04-27"
                              ).toISOString();
                              return (
                                artist.time > minDate && artist.time < maxDate
                              );
                            })
                            .map((artist) => {
                              return (
                                <Link
                                  key={artist.day + artist._id}
                                  to={`/${artist.slug.current}`}
                                >
                                  <div
                                    key={artist._id}
                                    className="artist-programme-entry"
                                  >
                                    <div className="programme-time">
                                      {new Date(artist.time).toLocaleTimeString(
                                        "en-GB",
                                        { timeStyle: "short" }
                                      )}
                                    </div>
                                    <div className="programme-title">
                                      {artist.title}
                                    </div>
                                    <div className="programme-price">
                                      {artist.price}
                                    </div>
                                    <div className="programme-place">
                                      {artist.place}
                                    </div>
                                  </div>
                                </Link>
                              );
                            })}
                        </>
                      ) : (
                        <>
                          {pageContent.artists
                            .filter((artist) => {
                              let minDate = new Date(
                                "2025-04-26"
                              ).toISOString();
                              let maxDate = new Date(
                                "2025-04-27"
                              ).toISOString();
                              return (
                                artist.time > minDate && artist.time < maxDate
                              );
                            })
                            .map((artist) => {
                              return (
                                <Link
                                  key={artist.day + artist._id}
                                  to={`/${artist.slug.current}`}
                                >
                                  <div
                                    key={artist._id}
                                    className="artist-programme-entry"
                                  >
                                    <div className="mobile-artist-entry">
                                      <div className="programme-time">
                                        {new Date(
                                          artist.time
                                        ).toLocaleTimeString("en-GB", {
                                          timeStyle: "short",
                                        })}
                                      </div>
                                      <div className="programme-title">
                                        {artist.title}
                                      </div>
                                    </div>
                                    <div className="mobile-artist-entry">
                                      <div className="programme-price">
                                        {artist.price}
                                      </div>
                                      <div className="programme-place">
                                        {artist.place}
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              );
                            })}
                        </>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {/* SØNDAG */}
                  {doesDayHaveArtists(pageContent.artists, 27) ? (
                    <div className="artist-programme-day">
                      <div className="artist-programme-day-title">
                        <div className="dot-wrapper">
                          <Dot />
                        </div>
                        SØNDAG 27. APRIL
                      </div>

                      {!isNarrow ? (
                        <>
                          {pageContent.artists
                            .filter((artist) => {
                              let minDate = new Date(
                                "2025-04-27"
                              ).toISOString();
                              let maxDate = new Date(
                                "2025-04-28"
                              ).toISOString();
                              return (
                                artist.time > minDate && artist.time < maxDate
                              );
                            })
                            .map((artist) => {
                              return (
                                <Link
                                  key={artist.day + artist._id}
                                  to={`/${artist.slug.current}`}
                                >
                                  <div
                                    key={artist._id}
                                    className="artist-programme-entry"
                                  >
                                    <div className="programme-time">
                                      {new Date(artist.time).toLocaleTimeString(
                                        "en-GB",
                                        { timeStyle: "short" }
                                      )}
                                    </div>
                                    <div className="programme-title">
                                      {artist.title}
                                    </div>
                                    <div className="programme-price">
                                      {artist.price}
                                    </div>
                                    <div className="programme-place">
                                      {artist.place}
                                    </div>
                                  </div>
                                </Link>
                              );
                            })}
                        </>
                      ) : (
                        <>
                          {pageContent.artists
                            .filter((artist) => {
                              let minDate = new Date(
                                "2025-04-27"
                              ).toISOString();
                              let maxDate = new Date(
                                "2025-04-28"
                              ).toISOString();
                              return (
                                artist.time > minDate && artist.time < maxDate
                              );
                            })
                            .map((artist) => {
                              return (
                                <Link
                                  key={artist.day + artist._id}
                                  to={`/${artist.slug.current}`}
                                >
                                  <div
                                    key={artist._id}
                                    className="artist-programme-entry"
                                  >
                                    <div className="mobile-artist-entry">
                                      <div className="programme-time">
                                        {new Date(
                                          artist.time
                                        ).toLocaleTimeString("en-GB", {
                                          timeStyle: "short",
                                        })}
                                      </div>
                                      <div className="programme-title">
                                        {artist.title}
                                      </div>
                                    </div>
                                    <div className="mobile-artist-entry">
                                      <div className="programme-price">
                                        {artist.price}
                                      </div>
                                      <div className="programme-place">
                                        {artist.place}
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              );
                            })}
                        </>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Accordion>
          ) : (
            ""
          )}
          <Accordion
            title={
              <div className="menu-title">
                S<span className="b1">Ø</span>
              </div>
            }
          >
            <div className="accordion-section SO-section">
              <div className="block">
                <BlockContent
                  blocks={pageContent.SO.text}
                  serializers={serializers}
                  projectId={sanityClient.clientConfig.projectId}
                  dataset={sanityClient.clientConfig.dataset}
                />
              </div>
            </div>
          </Accordion>
          {pageContent.info.infoReady ? (
            <Accordion
              title={
                <div className="menu-title">
                  PR
                  <span className="b2">A</span>
                  KTI
                  <span className="b1">S</span>K I<span className="b2">N</span>
                  FO
                </div>
              }
            >
              <div className="accordion-section info-section">
                <div className="block">
                  <BlockContent
                    blocks={pageContent.info.text}
                    serializers={serializers}
                    projectId={sanityClient.clientConfig.projectId}
                    dataset={sanityClient.clientConfig.dataset}
                  />
                </div>
              </div>
            </Accordion>
          ) : (
            ""
          )}
          {pageContent.about.aboutReady ? (
            <Accordion
              title={
                <div className="menu-title">
                  OM S<span className="b2">O</span>
                  DDJA
                  <span className="b1">Z</span>Z
                </div>
              }
            >
              <div className="accordion-section about-section">
                <div className="block">
                  <BlockContent
                    blocks={pageContent.about.text}
                    serializers={serializers}
                    projectId={sanityClient.clientConfig.projectId}
                    dataset={sanityClient.clientConfig.dataset}
                  />
                </div>
              </div>
            </Accordion>
          ) : (
            ""
          )}
          {pageContent.festivalArtist.festivalArtistReady ? (
            <Accordion
              title={
                <div className="menu-title">
                  FESTI
                  <span className="b1">V</span>
                  ALKUN
                  <span className="b2">S</span>
                  TNER
                </div>
              }
            >
              <div className="accordion-section festivalArtist-section">
                <div className="block">
                  <BlockContent
                    blocks={pageContent.festivalArtist.text}
                    serializers={serializers}
                    projectId={sanityClient.clientConfig.projectId}
                    dataset={sanityClient.clientConfig.dataset}
                  />
                </div>
              </div>
            </Accordion>
          ) : (
            ""
          )}

          {pageContent.contact.contactReady ? (
            <Accordion
              title={
                <div className="menu-title">
                  K<span className="b1">O</span>
                  NTAK
                  <span className="b2">T</span>
                </div>
              }
            >
              <div className="accordion-section contact-section">
                <div className="block col-2">
                  <BlockContent
                    blocks={pageContent.contact.textCol1}
                    serializers={serializers}
                    projectId={sanityClient.clientConfig.projectId}
                    dataset={sanityClient.clientConfig.dataset}
                  />
                  <BlockContent
                    blocks={pageContent.contact.textCol2}
                    serializers={serializers}
                    projectId={sanityClient.clientConfig.projectId}
                    dataset={sanityClient.clientConfig.dataset}
                  />
                </div>
              </div>
            </Accordion>
          ) : (
            ""
          )}
          {pageContent.partners.partnersReady ? (
            <Accordion
              title={
                <div className="menu-title">
                  PA
                  <span className="b2">R</span>
                  TN
                  <span className="b2">E</span>
                  RE
                </div>
              }
            >
              <div className="accordion-section partner-section">
                {pageContent.partners.partnerList.map((partner) => {
                  return (
                    <div key={partner.url} className="partner-wrapper">
                      <div className="partner-image-wrapper">
                        <img src={partner.url} />
                      </div>
                      <div className="partner-text">{partner.caption}</div>
                    </div>
                  );
                })}
              </div>

              {/* <div className='accordion-section partner-section'>
                    {pageContent.partners.partnerList.map(partner =>{
                        return(
                                <div key={partner.url} className='partner-wrapper'>
                                    <div className='partner-image-wrapper'>
                                        <img src={partner.url}/>
                                    </div>
                                    <div className='partner-text'>
                                        {partner.caption}
                                    </div>
                                </div>
                        )
                    })}
                </div> */}
            </Accordion>
          ) : (
            ""
          )}
          <div className="main-partners">
            {pageContent.partners.mainPartnerList.map((partner) => {
              return (
                <div key={partner.url} className="main-partner-wrapper">
                  <div className="main-partner-image-wrapper">
                    <img src={partner.url} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {!isNarrow ? (
        <div className="half">
          {displayArtist ? (
            <ArtistPage
              ticketUrl={pageContent.control.ticketUrl}
              content={displayArtist[0]}
              handleCloseArtist={handleCloseArtist}
            />
          ) : (
            ""
          )}
          <div style={{ display: `${contentVisible ? "block" : "none"}` }}>
            <SoddVideo
              // isPlaying={contentVisible}
              autoPlay
              playsInline
              muted
              loop
              src={
                process.env.PUBLIC_URL +
                "/assets/Soddjazz2025-webside_2_s_01.mp4"
              }
              type="video/mp4"
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {/* <div className="hay-container">
        <div
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "/assets/hay_square_02.png"
            }`,
          }}
          className="hay"
        ></div>
      </div> */}
    </div>
  );
};

export default Frontpage;
